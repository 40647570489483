/* eslint-disable @atlaskit/design-system/no-legacy-icons */
import React, { useContext, useEffect, useRef, useState, useCallback } from 'react';
import { css, styled, keyframes } from '@compiled/react';
import memoize from 'memoize-one';
import { useIntl } from 'react-intl-next';

import { token } from '@atlaskit/tokens';
import { B50 } from '@atlaskit/theme/colors';
import { Inline, Text, xcss, Box } from '@atlaskit/primitives';

import {
	VIEW_INLINE_COMMENT_EXPERIENCE,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import { CommentRenderer } from '@confluence/comment';
import { scrollCommentIntoView } from '@confluence/comments-util';
import type {
	SitePermissionType,
	CommentInlineResolveProperties,
	ReactionsSummary,
} from '@confluence/inline-comments-queries';
import { fg } from '@confluence/feature-gating';

import type { CommentPermissions, InlineCommentsMode, CommentAction } from './inlineCommentsTypes';
import { i18n } from './inlineCommentsi18n';
import { CommentActions } from './CommentActions';
import { CommentAuthor } from './CommentAuthor';
import { HighlightLineSVG } from './assets/HighlightLineSVG';

type CommentContainerProps = {
	isFocused?: boolean;
	isRemoving?: boolean;
	mode: InlineCommentsMode;
	isCommentsPanel?: boolean;
};

const fadeOut = keyframes({
	from: { opacity: 1 },
	to: { opacity: 0.5 },
});

const unreadIconStyle = css({ paddingTop: token('space.050', '3px') });

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CommentContainer = styled.div<CommentContainerProps>(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	`
  display: flex;
  flex-direction: column;
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766 */ ''}
	padding: ${(props: CommentContainerProps) => {
		return props.mode === 'view-all'
			? token('space.400', '32px')
			: props.isCommentsPanel === true
				? 'inherit'
				: `${token('space.150', '12px')} ${token('space.200', '16px')}`;
	}};
  border-left: 0;
  border-top: 0;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766 */ ''}
  ${(props: CommentContainerProps) => Boolean(props.isRemoving) && `animation: ${fadeOut};`}
  animation-timing-function: linear;
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766 */ ''}
  cursor: ${(props: CommentContainerProps) =>
		Boolean(props.isRemoving) ? 'not-allowed' : 'inherit'};
  outline: none;

${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766 */ ''}
  background: ${(props: CommentContainerProps) =>
		Boolean(props.isFocused) ? `${token('color.background.selected', B50)}` : undefined};

  blockquote {
    color: ${token('color.text.subtlest', '#707070')};
  }

  &:last-of-type {
    border-bottom: 0;
  }
`,
);

const warningMessageStyle = xcss({
	display: 'flex',
	fontStyle: 'italic',
	padding: 'space.150',
});

const annotatedTextBodyStyle = xcss({
	borderStyle: 'solid',
	borderColor: 'color.border',
	borderRadius: 'border.radius.100',
	paddingTop: 'space.100',
	paddingRight: 'space.150',
	paddingBottom: 'space.100',
	paddingLeft: 'space.150',
	width: '100%',
});

// This snippet is being used in RepliesOpener.tsx
const dotStyles = xcss({
	height: token('space.100', '8px'),
	width: token('space.100', '8px'),
	backgroundColor: 'color.background.brand.bold',
	borderRadius: '50%',
	margin: 'space.050',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RelativeContainer = styled.div({
	position: 'relative',
	height: '1px',
});

const highlightTextBoxStyles = xcss({
	paddingTop: 'space.150',
	paddingBottom: 'space.100',
});

const highlightSvgBoxStyles = xcss({
	height: '22px',
	width: '2px',
});

type CommentBodyProps = {
	pageId: string;
	pageType: string;
	avatarUrl?: string;
	commentId: string;
	content: string;
	date: string;
	displayName?: string | null;
	permissions: CommentPermissions;
	dateUrl?: string;
	userId?: string | null;
	deleteComment?: () => void;
	editComment?: () => void;
	resolveComment?: () => void;
	isReply: boolean;
	isFocused?: boolean;
	shouldScrollIntoView?: boolean;
	isRemoving?: boolean;
	numReplies?: number;
	isFabricPage?: boolean;
	isCommentActive?: boolean;
	isCurrentUserAnonymous?: boolean;
	mode: InlineCommentsMode;
	onRendered?: () => void;
	onClose?: () => void;
	shouldAutofocus?: boolean;
	onAutoFocused?: () => void;
	permissionType?: SitePermissionType;
	maxHeight?: number;
	fadeOutHeight?: number;
	onComplete?: () => void;
	supportedActions?: CommentAction[];
	isUnread?: boolean;
	unreadCommentRef?: React.RefObject<HTMLDivElement>;
	isCommentsPanel?: boolean;
	replyCount?: number;
	isHovered?: boolean;
	isResolvedByAnotherUser?: boolean;
	resolveProperties?: CommentInlineResolveProperties;
	annotationId?: string;
	annotatedText?: string | null;
	reopenComment?: () => void;
	replyToComment?: (commentId: string) => void;
	inheritedReactionsData?: ReactionsSummary | null;
	isDeletedByAnotherUser?: boolean;
	shouldShowRemovedCommentContent?: boolean;
};

// Parse content to JSON or Fail view inline experience and return null
const parseContent = memoize((content, experienceTracker) => {
	if (!content?.length) return null;

	try {
		return JSON.parse(content);
	} catch (error) {
		experienceTracker.stopOnError({
			name: VIEW_INLINE_COMMENT_EXPERIENCE,
			error,
		});
		return null;
	}
});

const replyBranchStyles = css({
	position: 'relative',
	// for the curvy border
	'&::before': {
		content: "''",
		position: 'absolute',
		left: '-24px',
		top: '0px',
		height: '20px',
		width: '12px',
		borderLeft: `2px solid ${token('color.background.accent.gray.subtlest.hovered')}`,
		borderBottom: `2px solid ${token('color.background.accent.gray.subtlest.hovered')}`,
		borderBottomLeftRadius: '7px',
	},
});

export const CommentBody = ({
	pageId,
	pageType,
	avatarUrl,
	commentId,
	content,
	date,
	displayName,
	permissions,
	dateUrl,
	userId,
	deleteComment,
	editComment,
	resolveComment,
	isReply,
	isFocused,
	shouldScrollIntoView,
	isRemoving,
	numReplies,
	isFabricPage,
	isCommentActive,
	isCurrentUserAnonymous,
	mode,
	onRendered,
	onClose,
	shouldAutofocus,
	onAutoFocused,
	permissionType,
	maxHeight,
	fadeOutHeight,
	onComplete,
	supportedActions = ['edit', 'delete', 'resolve'],
	isUnread = false,
	unreadCommentRef,
	isCommentsPanel = false,
	isHovered,
	isResolvedByAnotherUser = false,
	resolveProperties,
	annotationId,
	annotatedText,
	reopenComment,
	replyToComment,
	inheritedReactionsData,
	isDeletedByAnotherUser,
	shouldShowRemovedCommentContent,
}: CommentBodyProps) => {
	const { formatMessage } = useIntl();
	const containerRef = useRef<HTMLDivElement | null>(null);
	const [showingCopyLinkButton, setShowingCopyLink] = useState(false);
	const [hasFallbackContent, setHasFallbackContent] = useState(false);
	const [overflowPortal, setOverflowPortal] = useState<HTMLDivElement | undefined>(undefined);

	const experienceTracker = useContext(ExperienceTrackerContext);

	useEffect(() => {
		/* We only need to scroll for focused replies as parent comments will have scrolled when selected */
		if (shouldScrollIntoView && isFocused && containerRef && containerRef.current) {
			scrollCommentIntoView(containerRef.current, isFabricPage);
		}
	}, [containerRef, isFocused, shouldScrollIntoView, isFabricPage]);

	useEffect(() => {
		if (shouldAutofocus) {
			containerRef.current && containerRef.current.focus();
			onAutoFocused && onAutoFocused();
		}
	}, [containerRef, shouldAutofocus, onAutoFocused]);

	const adf = parseContent(content, experienceTracker);

	const wrapperLabel = formatMessage(i18n.a11yCommentLabel, {
		username: displayName,
	});

	const onCommentContentFallback = useCallback(() => setHasFallbackContent(true), []);

	const handleRef = (portalDiv: HTMLDivElement) => {
		/* do a check to prevent from re-running setPopupPortal multiple times*/
		if (!overflowPortal && portalDiv) {
			setOverflowPortal(portalDiv);
		}
	};

	const handleMouseEventOnWrapper = (eventType: 'enter' | 'leave') => () => {
		setShowingCopyLink(eventType === 'enter');
	};

	const commentRemovedByAnotherUser = isDeletedByAnotherUser || isResolvedByAnotherUser;
	const displayNameToShow =
		isResolvedByAnotherUser && !shouldShowRemovedCommentContent
			? formatMessage(i18n.resolvedText)
			: isDeletedByAnotherUser && !shouldShowRemovedCommentContent
				? formatMessage(i18n.deletedText)
				: displayName;
	const shouldShowOverflowMenu = supportedActions.length > 0;

	const showRepliesWithCurvedBorders =
		isCommentsPanel && isReply && fg('confluence_frontend_comments_panel_v2');

	return (
		<CommentContainer
			ref={containerRef}
			data-comment-id={commentId}
			data-testid={commentId}
			isFocused={isFocused}
			isRemoving={isRemoving}
			mode={mode}
			isCommentsPanel={isCommentsPanel}
			aria-label={wrapperLabel}
			// eslint-disable-next-line jsx-a11y/aria-role
			role="comment"
			tabIndex={-1}
			onMouseEnter={handleMouseEventOnWrapper('enter')}
			onMouseLeave={handleMouseEventOnWrapper('leave')}
		>
			<div ref={unreadCommentRef}>
				{mode !== 'view' && (
					<RelativeContainer>
						<div data-testid="overflow-container" ref={handleRef} />
					</RelativeContainer>
				)}
				<div css={showRepliesWithCurvedBorders && replyBranchStyles}>
					<Inline spread="space-between">
						<CommentAuthor
							commentMode="view"
							userId={userId}
							date={date}
							displayName={displayNameToShow}
							avatarUrl={avatarUrl}
							commentDateUrl={dateUrl}
							commentId={isCurrentUserAnonymous ? commentId : null}
							permissionType={permissionType}
							isInactiveComment={Boolean(mode === 'view-all' && !isCommentActive)}
							showCopyLink={showingCopyLinkButton}
							resolveProperties={resolveProperties}
							isCommentsPanel={isCommentsPanel}
						/>
						{isUnread && (
							<div css={unreadIconStyle} data-testid={`unread-comment-indicator-${commentId}`}>
								<Box xcss={dotStyles} />
							</div>
						)}
					</Inline>
				</div>
				{isCommentsPanel && !isReply && (
					<>
						{resolveProperties?.resolved && annotatedText && shouldShowRemovedCommentContent && (
							<Inline
								space="space.150"
								alignBlock="center"
								xcss={highlightTextBoxStyles}
								testId="highlight-text"
							>
								<Box xcss={highlightSvgBoxStyles}>
									<HighlightLineSVG />
								</Box>
								<Text color="color.text.subtle" size="medium" maxLines={1}>
									{annotatedText}
								</Text>
							</Inline>
						)}
						{!resolveProperties?.resolved && fg('confluence_frontend_comments_panel_v2') && (
							<Inline
								space="space.150"
								alignBlock="center"
								xcss={highlightTextBoxStyles}
								testId="highlight-text"
							>
								<Box
									data-testId="annotated-section-for-inlineComment"
									xcss={annotatedTextBodyStyle}
									as="div"
								>
									<Text size="medium" maxLines={3}>
										{annotatedText}
									</Text>
								</Box>
							</Inline>
						)}
					</>
				)}
				{commentRemovedByAnotherUser && !shouldShowRemovedCommentContent ? (
					<Box xcss={warningMessageStyle}>
						<Text weight="bold">
							{(isResolvedByAnotherUser && formatMessage(i18n.resolvedCommentActionLabel)) ||
								(isDeletedByAnotherUser && formatMessage(i18n.deletedCommentActionLabel))}
						</Text>
					</Box>
				) : (
					adf && (
						<CommentRenderer
							commentId={commentId}
							adf={adf}
							onContentFallback={onCommentContentFallback}
							isTruncatedContent={mode === 'view-all' && !isCommentActive}
							onRendered={onRendered}
							onComplete={onComplete}
							maxHeight={maxHeight}
							fadeOutHeight={fadeOutHeight}
							isInlineComment
							isCommentsPanel={isCommentsPanel}
						/>
					)
				)}
				{shouldShowOverflowMenu && (
					<CommentActions
						pageId={pageId}
						pageType={pageType}
						commentId={commentId}
						permissions={permissions}
						isReply={isReply}
						deleteComment={deleteComment}
						editComment={editComment}
						resolveComment={resolveComment}
						restrictEdit={hasFallbackContent}
						restrictDelete={Boolean(numReplies)}
						numReplies={numReplies}
						isCommentActive={isCommentActive}
						mode={mode}
						commentDateUrl={dateUrl}
						onClose={onClose}
						overflowMenuPortal={overflowPortal}
						supportedActions={supportedActions}
						isCommentsPanel={isCommentsPanel}
						isHovered={isHovered}
						annotationId={annotationId}
						resolveProperties={resolveProperties}
						reopenComment={reopenComment}
						replyToComment={replyToComment}
						isUnread={isUnread}
						inheritedReactionsData={inheritedReactionsData}
					/>
				)}
			</div>
		</CommentContainer>
	);
};

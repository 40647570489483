import type { FC } from 'react';
import React, { Fragment, useContext, useEffect, useState, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { FormattedMessage, useIntl } from 'react-intl-next';
import { styled } from '@compiled/react';
import { useQuery } from '@apollo/react-hooks';

import { token } from '@atlaskit/tokens';
import { IconButton } from '@atlaskit/button/new';
import { N0, N30, N200, N700, B200, B400 } from '@atlaskit/theme/colors';
import ShowMoreHorizontalIcon from '@atlaskit/icon/core/show-more-horizontal';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import { Box, Text, Pressable, xcss } from '@atlaskit/primitives';

import {
	VIEW_REACTIONS_EXPERIENCE,
	DELETE_INLINE_COMMENT_EXPERIENCE,
	EDIT_INLINE_COMMENT_EXPERIENCE,
	EDIT_INLINE_COMMENT_LOAD_EXPERIENCE,
	EDIT_INLINE_COMMENT_PUBLISH_EXPERIENCE,
	RESOLVE_INLINE_COMMENT_EXPERIENCE,
	ExperienceTrackerContext,
	startEditorExperiences,
} from '@confluence/experience-tracker';
import { DisabledCommentAction } from '@confluence/comment';
import { fg } from '@confluence/feature-gating';
import { CommentWarningDialog } from '@confluence/comment-dialogs';
import type { ReactionContainerType, ReactionsCommentsQueryData } from '@confluence/reactions';
import { Reactions, ReactionsCommentsQuery, ReactionLocation } from '@confluence/reactions';
import {
	ReactionsContext,
	useCommentContentContext,
	useCommentContentDispatchContext,
} from '@confluence/comment-context';
import { useIsCurrentPageLive } from '@confluence/live-pages-utils/entry-points/useIsCurrentPageLive';
import { useDialogs } from '@confluence/dialogs/entry-points/useDialogs';
import {
	type ReactionsSummary,
	type CommentInlineResolveProperties,
} from '@confluence/inline-comments-queries';
import { useCommentsDataActions } from '@confluence/comments-data';
import {
	isClipboardApiSupported,
	isIEClipboardApiSupported,
	copyToClipboard,
	copyToClipboardIE,
} from '@confluence/comments-util';

import { InlineCommentMode } from './enum/InlineCommentMode';
import { InlineCommentFramework } from './enum/InlineCommentFramework';
import type { CommentPermissions, InlineCommentsMode, CommentAction } from './inlineCommentsTypes';
import { ActionsContainer } from './styled-components';
import { i18n } from './inlineCommentsi18n';

type CommentActionsProps = {
	pageId: string;
	pageType: string;
	commentId: string;
	permissions: CommentPermissions;
	isReply: boolean;
	restrictEdit?: boolean;
	restrictDelete?: boolean;
	deleteComment?: () => void;
	editComment?: () => void;
	resolveComment?: () => void;
	numReplies?: number;
	isCommentActive?: boolean;
	mode: InlineCommentsMode;
	onClose?: () => void;
	overflowMenuPortal?: HTMLDivElement | null;
	supportedActions?: CommentAction[];
	isCommentsPanel?: boolean;
	isHovered?: boolean;
	annotationId?: string;
	resolveProperties?: CommentInlineResolveProperties;
	reopenComment?: () => void;
	replyToComment?: (commentId: string) => void;
	isUnread?: boolean;
	inheritedReactionsData?: ReactionsSummary | null;
	commentDateUrl?: string;
};

type DropdownMenuContainerProps = {
	isActive?: boolean;
	isCommentActive?: boolean;
	mode: InlineCommentsMode;
	showClose: boolean;
	enableDropdownForEditMode: boolean;
	isCommentsPanel: boolean;
	isUnread: boolean;
};

// WS-2470: We need to override this for correct placement of disabled actions in the overflow menu
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OverflowDisabledDropdownItem = styled(DropdownItem)({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	padding: '0 !important',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DropdownMenuContainer = styled.span<DropdownMenuContainerProps>(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	`
    position: absolute;
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766 */ ''}
    top: ${(props: DropdownMenuContainerProps) =>
			props.isCommentsPanel
				? '0px'
				: props.mode === 'view'
					? '12px'
					: props.enableDropdownForEditMode
						? token('space.negative.400', '-32px')
						: props.showClose
							? token('space.negative.050', '-3px')
							: token('space.negative.025', '-1px')};
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766 */ ''}
    right: ${(props: DropdownMenuContainerProps) =>
			props.mode !== 'view'
				? props.enableDropdownForEditMode
					? token('space.300', '24px')
					: props.showClose
						? token('space.300', '22px')
						: token('space.050', '3px')
				: props.isCommentsPanel
					? !props.isUnread
						? token('space.0', '0px')
						: token('space.300', '22px')
					: token('space.500', '41px')};
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766 */ ''}
    visibility: ${(props: DropdownMenuContainerProps) =>
			!props.isCommentActive && props.mode === 'view-all' ? 'hidden' : 'visible'};

    button, button:hover {
      width: 24px;
      height: 24px;
      border-radius: 4px;
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766 */ ''}
      ${(props: DropdownMenuContainerProps) =>
				props.isActive
					? `background-color: ${token('color.background.accent.gray.bolder', N700)}`
					: `&:hover { background-color: ${token('color.background.accent.gray.subtler', N30)}; }`}
    };
    & span[role="img"], > svg {
      height: 20px;
      width: 20px;
    }
  `,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
const ReplyContainer = styled.div<{ hasReactions: boolean }>({
	alignContent: 'center',
	cursor: 'pointer',
	'&::before': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
		content: (props) => (props.hasReactions ? 'none' : "'•'"),
		display: 'inline-block',
		padding: `0 ${token('space.050', '4px')}`,
		textDecoration: 'none',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
const ReactionsContainer = styled.div<{ hasReactions: boolean; isCommentsPanel: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	display: (props) => (props.hasReactions ? 'block' : 'inline-flex'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	flexBasis: (props) => (props.hasReactions ? '100%' : 'auto'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	marginTop: (props) =>
		props.isCommentsPanel && !props.hasReactions ? token('space.negative.050', '-4px') : '0',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	marginLeft: (props) =>
		props.isCommentsPanel && !props.hasReactions ? token('space.negative.050', '-4px') : '0',
});

type ActionItemProps = {
	mode?: InlineCommentsMode;
	isReply?: boolean;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
const ActionItem = styled.li<ActionItemProps>(`
  display: inline-flex;
  flex-flow: row nowrap;
  color: ${token('color.text.subtle', N200)};
  min-height: 24px;
  align-items: center;

  a {
    align-items: flex-end;
    color: ${token('color.text.brand', B400)};
    flex: 2;
    text-align: right;
	font: ${token('font.body.small')};
    padding-top: ${token('space.050', '4px')};
    cursor: pointer;
  }

  & button {
    color: ${token('color.text.subtle', N200)};
  }

  & button:focus {
    outline: 2px solid ${token('color.border.focused', B200)};
  }

  &:not(:last-of-type):after {
    content: "•";
    color: ${token('color.text.subtle', N200)};
    padding: 0 ${token('space.050', '4px')} 0 ${token('space.050', '4px')};
  }

  &:first-child:after {
    display: none;
  }
`);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
const ActionButton = styled.button(`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font-size: inherit;
  cursor: pointer;
  outline: inherit;

  &:focus {
    outline: 2px solid ${token('color.border.focused', B200)};
    outline-offset: 2px;
  }

  :hover span {
    text-decoration: underline;
  }
`);

const pressableStyles = xcss({
	':hover': {
		textDecoration: 'underline',
	},
});

const inlineActionsContainerStylesWithNoReactions = xcss({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	maxHeight: token('space.300', '24px'),
});

const inlineActionsContainerStylesWithReactions = xcss({
	display: 'flex',
	flexDirection: 'column',
	gap: 'space.150',
});

export const CommentActions: FC<CommentActionsProps> = ({
	pageId,
	pageType,
	commentId,
	permissions,
	isReply,
	restrictEdit,
	restrictDelete,
	deleteComment,
	editComment,
	resolveComment,
	isCommentActive,
	mode,
	onClose,
	overflowMenuPortal,
	supportedActions = ['edit', 'delete', 'resolve'],
	isCommentsPanel = false,
	isHovered,
	annotationId,
	resolveProperties,
	reopenComment,
	replyToComment,
	isUnread = false,
	inheritedReactionsData,
	commentDateUrl,
}) => {
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [useDropdownMenu, setUseDropdownMenu] = useState(false);
	const [isEditable, setIsEditable] = useState(false);
	const [isRemovable, setIsRemovable] = useState(false);
	const [isResolvable, setIsResolvable] = useState(false);
	const [canBeReopened, setCanBeReopened] = useState(false);
	const experienceTracker = useContext(ExperienceTrackerContext);
	const { isReactionsEnabled } = useContext(ReactionsContext);
	const [reactionsCount, setReactionsCount] = useState(0);

	const { hasContentChanged } = useCommentContentContext();
	const { resetContentChanged } = useCommentContentDispatchContext();
	const { showModal } = useDialogs();
	const { updateReactionsSummary } = useCommentsDataActions();

	const isLivePage = useIsCurrentPageLive();
	const { formatMessage } = useIntl();

	const handleGqlError = (error: Error) => {
		experienceTracker.stopOnError({
			name: VIEW_REACTIONS_EXPERIENCE,
			error,
		});
	};

	const { data: reactionsData, loading: reactionsLoading } = useQuery<ReactionsCommentsQueryData>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		ReactionsCommentsQuery,
		{
			variables: { contentId: commentId, containerId: pageId },
			onError: handleGqlError,
			fetchPolicy: 'cache-and-network',
			// If we have the reactions from a higher component, skip the query
			skip: Boolean(inheritedReactionsData),
		},
	);

	const inlineCommentExists = (inlineMarkerRef: string | null) => {
		const tiny = `.inline-comment-marker[data-ref="${inlineMarkerRef}"]`;
		const fabricAnnotation = `span[data-mark-annotation-type="inlineComment"][data-id="${inlineMarkerRef}"]`;
		const editorAnnotation = `[id="${inlineMarkerRef}"], [annotationtype="inlineComment"]`;

		return Boolean(document.querySelector(`${tiny}, ${fabricAnnotation}, ${editorAnnotation}`));
	};

	useEffect(() => {
		// If we have the reactions from a higher component, use that count
		const count =
			(inheritedReactionsData === undefined
				? reactionsData?.comments?.nodes?.[0]?.reactionsSummary?.reactionsCount
				: inheritedReactionsData?.reactionsCount) || 0;

		setReactionsCount(count);
	}, [reactionsData, inheritedReactionsData]);

	useEffect(() => {
		// Actions are decided by the mode we're displaying
		// and the permissions available for the user
		const canEdit = Boolean(
			permissions?.isEditable && editComment && supportedActions.includes('edit'),
		);
		const canDelete = Boolean(
			permissions?.isRemovable && deleteComment && supportedActions.includes('delete'),
		);
		const canResolve = Boolean(
			permissions?.isResolvable && resolveComment && supportedActions.includes('resolve'),
		);

		// Don't show the reopen link if user doesn't have permissions, for dangling comments, or if the mark doesn't exist in the doc
		const canReopen = Boolean(
			permissions?.isResolvable &&
				reopenComment &&
				supportedActions.includes('reopen') &&
				!isReply &&
				!resolveProperties?.resolvedByDangling &&
				annotationId &&
				inlineCommentExists(annotationId),
		);
		setCanBeReopened(canReopen);

		if (mode === 'view' || (mode === 'edit' && fg('confluence-frontend-comments-panel'))) {
			if (!isReply) {
				setIsResolvable(canResolve); // Parent comments are resolvable if allowed
			} else {
				setIsResolvable(false); // Replies cannot be resolved
			}

			if (isCommentsPanel) {
				const hasPermissions = canEdit || canDelete || canResolve;
				const shouldShowDropdownMenu = !!isHovered && hasPermissions;
				// Set the state for using the dropdown menu
				setUseDropdownMenu(shouldShowDropdownMenu);
			} else if (!isReply && (canEdit || canDelete || canResolve)) {
				setUseDropdownMenu(true); // Parent comments or replies outside panel can show menu
			}

			// Users can delete the replies they created
			setIsRemovable(canDelete);
			setIsEditable(canEdit);
		} else {
			setIsRemovable(canDelete);
			setIsEditable(canEdit);
			setIsResolvable(canResolve && !isReply);
		}
	}, [
		mode,
		permissions,
		isHovered,
		isReply,
		isCommentsPanel,
		supportedActions,
		deleteComment,
		editComment,
		resolveComment,
		reopenComment,
		resolveProperties?.resolvedByDangling,
		annotationId,
	]);

	useEffect(() => {
		// If the user is on the comments panel and leaves this comment and the dropdown menu is open,
		// close the dropdown menu so they don't see it when they return
		if (isCommentsPanel && isDropdownOpen && !isHovered) {
			setIsDropdownOpen(false);
		}
	}, [isCommentsPanel, isDropdownOpen, isHovered, setIsDropdownOpen]);

	const getInlineCommentModeConstant = () => {
		switch (mode) {
			case 'view':
				return InlineCommentMode.VIEW;
			case 'edit':
				if (isLivePage) {
					return InlineCommentMode.LIVE;
				}
				return InlineCommentMode.EDIT;
		}
	};

	const handleDelete = () => {
		experienceTracker.start({
			name: DELETE_INLINE_COMMENT_EXPERIENCE,
			attributes: {
				mode: getInlineCommentModeConstant(),
				framework: InlineCommentFramework.ANNOTATION_PROVIDER,
				isReply,
			},
		});

		showModal(CommentWarningDialog, {
			onConfirm: () => {
				deleteComment && deleteComment();
			},
			isDelete: true,
			onCancel: () => {
				// abort delete experience if dialog is closed
				experienceTracker.abort({
					name: DELETE_INLINE_COMMENT_EXPERIENCE,
					reason: 'delete dialog closed',
				});
			},
		});
	};

	const handleEditComment = () => {
		startEditorExperiences(
			/* compoundExperience */ {
				name: EDIT_INLINE_COMMENT_EXPERIENCE,
				attributes: {
					mode: getInlineCommentModeConstant(),
					framework: InlineCommentFramework.ANNOTATION_PROVIDER,
					isReply,
					type: isReply ? 'reply' : 'topLevel',
				},
			},
			/* editorLoadExperience */ {
				name: EDIT_INLINE_COMMENT_LOAD_EXPERIENCE,
			},
			/* editorPublishExperience */ {
				name: EDIT_INLINE_COMMENT_PUBLISH_EXPERIENCE,
			},
		);
		editComment && editComment();
	};

	const handleReply = () => {
		if (hasContentChanged) {
			showModal(CommentWarningDialog, {
				onConfirm: () => {
					resetContentChanged();
					replyToComment && replyToComment(commentId);
				},
			});
		} else {
			replyToComment && replyToComment(commentId);
		}
	};

	const handleCopyLink = async () => {
		const linkToComment = `${window.location.origin}/wiki${commentDateUrl}`;

		if (isClipboardApiSupported()) {
			await copyToClipboard(linkToComment);
		} else if (isIEClipboardApiSupported()) {
			await copyToClipboardIE(linkToComment);
		}
	};

	const handleResolveComment = () => {
		const doResolve = () => {
			experienceTracker.start({
				name: RESOLVE_INLINE_COMMENT_EXPERIENCE,
				id: commentId,
				attributes: {
					mode: getInlineCommentModeConstant(),
					framework: InlineCommentFramework.ANNOTATION_PROVIDER,
				},
			});
			resolveComment && resolveComment();
		};

		if (hasContentChanged) {
			showModal(CommentWarningDialog, {
				onConfirm: () => {
					resetContentChanged();
					doResolve();
				},
			});
		} else {
			doResolve();
		}
	};

	const handleReopenComment = () => {
		reopenComment && reopenComment();
	};

	const renderEditAction = (isRestricted?: boolean, isDropdownMenu?: boolean) => {
		if (isDropdownMenu) {
			return isRestricted ? (
				<OverflowDisabledDropdownItem testId="disabled-edit-comment-dropdown-action">
					<DisabledCommentAction action="edit" isReply={isReply} isInOverflowMenu />
				</OverflowDisabledDropdownItem>
			) : (
				<DropdownItem
					testId="edit-comment-dropdown-action"
					data-cy="edit-comment-action"
					onClick={handleEditComment}
				>
					<FormattedMessage {...i18n.edit} />
				</DropdownItem>
			);
		}

		return isRestricted ? (
			<DisabledCommentAction action="edit" isReply={isReply} isInOverflowMenu />
		) : (
			<ActionButton
				data-testid="edit-comment-action"
				data-cy="edit-comment-action"
				onClick={handleEditComment}
			>
				<FormattedMessage {...i18n.edit} />
			</ActionButton>
		);
	};

	const renderDeleteAction = (isRestricted?: boolean, isDropdownMenu?: boolean) => {
		if (isDropdownMenu) {
			return isRestricted ? (
				<OverflowDisabledDropdownItem testId="disabled-delete-comment-dropdown-action">
					<DisabledCommentAction action="delete" isReply={isReply} isInOverflowMenu />
				</OverflowDisabledDropdownItem>
			) : (
				<DropdownItem testId="delete-comment-dropdown-action" onClick={handleDelete}>
					<FormattedMessage {...i18n.delete} />
				</DropdownItem>
			);
		}

		return isRestricted ? (
			<DisabledCommentAction action="delete" isReply={isReply} />
		) : (
			<ActionButton data-testid="delete-comment-action" onClick={handleDelete}>
				<FormattedMessage {...i18n.delete} />
			</ActionButton>
		);
	};

	const renderReopenAction = () => {
		return (
			<Pressable
				testId="reopen-comment-action"
				onClick={handleReopenComment}
				padding="space.0"
				backgroundColor="color.background.neutral.subtle"
				xcss={pressableStyles}
			>
				<Text color="color.text.subtlest" size="small">
					<FormattedMessage {...i18n.reopen} />
				</Text>
			</Pressable>
		);
	};

	const renderReplyAction = () => {
		return (
			<ReplyContainer hasReactions={reactionsCount > 0}>
				<ActionButton
					data-testid="reply-comment-action"
					data-cy="reply-comment-action"
					onClick={handleReply}
				>
					<FormattedMessage {...i18n.replyPlaceholder} />
				</ActionButton>
			</ReplyContainer>
		);
	};

	const renderCopyButton = () => {
		return (
			<DropdownItem testId="copy-link-dropdown-action" onClick={handleCopyLink}>
				<FormattedMessage {...i18n.copyLink} />
			</DropdownItem>
		);
	};

	const renderResolveOption = () => {
		// eslint-disable-next-line confluence-feature-gating/no-preconditioning
		if (fg('confluence-frontend-comments-panel') && isCommentsPanel && isReply) {
			return;
		}
		return (
			<DropdownItem testId="resolve-comment-dropdown-action" onClick={handleResolveComment}>
				<FormattedMessage {...i18n.resolve} />
			</DropdownItem>
		);
	};

	const renderDropdownMenu = (editRestricted?: boolean, deleteRestricted?: boolean) => {
		const shouldHideCommentsPanelDropdown =
			isCommentsPanel && !isEditable && !isRemovable && (isReply || !isResolvable);
		if (shouldHideCommentsPanelDropdown) {
			return <></>;
		}
		const dropdownMenu = (
			<DropdownMenuContainer
				data-cy="comment-overflow-actions-menu"
				data-testid="comment-overflow-actions-menu"
				isActive={isDropdownOpen}
				isCommentActive={isCommentActive}
				mode={mode}
				showClose={Boolean(onClose)}
				enableDropdownForEditMode={fg('confluence-frontend-comments-panel')}
				isCommentsPanel={isCommentsPanel}
				isUnread={isUnread}
			>
				<DropdownMenu
					testId="comment-overflow-menu"
					isOpen={isDropdownOpen}
					placement="bottom-end"
					onOpenChange={({ isOpen }) => {
						setIsDropdownOpen(isOpen);
					}}
					trigger={({ triggerRef, ...props }) => (
						<IconButton
							{...props}
							testId="comment-overflow-menu-trigger"
							ref={triggerRef}
							label={formatMessage(i18n.commentActionsMenuLabel)}
							appearance="subtle"
							icon={(iconProps) => (
								<ShowMoreHorizontalIcon
									{...iconProps}
									color={
										isDropdownOpen
											? token('color.icon.inverse', N0)
											: token('color.icon.subtle', N200)
									}
								/>
							)}
						/>
					)}
				>
					<DropdownItemGroup>
						{fg('confluence_frontend_comments_panel_v2') && renderCopyButton()}
						{isResolvable && renderResolveOption()}
						{isEditable && renderEditAction(editRestricted, useDropdownMenu)}
						{isRemovable && renderDeleteAction(deleteRestricted, useDropdownMenu)}
					</DropdownItemGroup>
				</DropdownMenu>
			</DropdownMenuContainer>
		);

		return overflowMenuPortal ? createPortal(dropdownMenu, overflowMenuPortal) : dropdownMenu;
	};

	const reactionsUpdateFn = useMemo(
		() =>
			inheritedReactionsData !== undefined
				? (emojiId: string, actionType: 'add' | 'delete', objectId: string) =>
						updateReactionsSummary(emojiId, actionType, objectId, annotationId)
				: undefined,
		[inheritedReactionsData, annotationId, updateReactionsSummary],
	);

	const renderReactions = () => {
		return (
			<ReactionsContainer hasReactions={reactionsCount > 0} isCommentsPanel={isCommentsPanel}>
				<Reactions
					contentId={commentId}
					containerId={pageId}
					containerType={pageType as ReactionContainerType}
					location={
						mode === 'edit'
							? ReactionLocation.EDITOR_INLINE_COMMENT
							: ReactionLocation.INLINE_COMMENT
					}
					hidePlaceholder={reactionsCount === 0}
					reactionsData={
						inheritedReactionsData === undefined
							? reactionsData?.comments?.nodes?.[0]?.reactionsSummary
							: inheritedReactionsData
					}
					// Only provide an update function if we supply this function with data
					updateCache={reactionsUpdateFn}
				/>
			</ReactionsContainer>
		);
	};

	return (
		<Fragment>
			{isCommentsPanel ? (
				<Box
					xcss={[
						reactionsCount > 0 && !resolveProperties?.resolved
							? inlineActionsContainerStylesWithReactions
							: inlineActionsContainerStylesWithNoReactions,
					]}
				>
					{canBeReopened && renderReopenAction()}
					{isReactionsEnabled &&
						!reactionsLoading &&
						!resolveProperties?.resolved &&
						renderReactions()}
					{/* eslint-disable-next-line confluence-feature-gating/no-preconditioning */}
					{!resolveProperties?.resolved &&
						fg('confluence-frontend-comments-panel') &&
						!fg('confluence_frontend_comments_panel_v2') &&
						renderReplyAction()}
				</Box>
			) : (
				<ActionsContainer>
					<ActionItem />
					{!isReply && isResolvable && (
						<ActionItem mode={mode} isReply={isReply}>
							<ActionButton
								data-testid="resolve-comment-action"
								data-cy="resolve-comment-action"
								onClick={handleResolveComment}
							>
								<FormattedMessage {...i18n.resolve} />
							</ActionButton>
						</ActionItem>
					)}
					{!useDropdownMenu && isEditable && (
						<ActionItem mode={mode} isReply={isReply}>
							{renderEditAction(restrictEdit, useDropdownMenu)}
						</ActionItem>
					)}
					{!useDropdownMenu && isRemovable && (
						<ActionItem mode={mode} isReply={isReply}>
							{renderDeleteAction(restrictDelete, useDropdownMenu)}
						</ActionItem>
					)}
					{isReactionsEnabled &&
						!reactionsLoading &&
						!resolveProperties?.resolved &&
						renderReactions()}
				</ActionsContainer>
			)}
			{useDropdownMenu && renderDropdownMenu(restrictEdit, restrictDelete)}
		</Fragment>
	);
};
